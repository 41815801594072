import React from "react";
import Auth from './Authentication'
import { Navigate, useSearchParams } from 'react-router-dom'

const DirectSignIn = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    Auth.login(searchParams.get("email"), searchParams.get("token"))
    return <Navigate to="/home"/>
}

export default DirectSignIn