import { useState, useCallback, useEffect } from "react";
import { DimensionSettings, ImperialDimensionSettings, ImperialPalletData, PalletData } from "../../helpers/GoodsHelper";
const CubicFeetMultiplier = 1728;
const MetricUnits = {distance: "cm", weight: "kg", volume: "m3", cbm: "CBM"}
const ImperialUnits = {distance: "in", weight: "lbs", volume: "ft3", cbm: "CBFT"}


const useMeasurement = (unit = "metric") => {
  const [measurementUnit, setMeasurementUnit] = useState(unit);
  const [units, setUnits] = useState(unit === 'metric' ? MetricUnits : ImperialUnits)
  const [dimensions, setDimensions] = useState(unit === 'metric' ? DimensionSettings : ImperialDimensionSettings)
  const [pallet, setPallet] = useState(unit === 'metric' ? PalletData : ImperialPalletData)
  
  useEffect(() => {    
    if (measurementUnit === "metric") {
      setUnits(MetricUnits)
      setDimensions(DimensionSettings)
      setPallet(PalletData)
    } else if (measurementUnit === "imperial") {
      setUnits(ImperialUnits)
      setDimensions(ImperialDimensionSettings)
      setPallet(ImperialPalletData)
    } else {
      throw new Error('Unsupported unit. Please use "metric" or "imperial".');
    }
  }, [measurementUnit])

  const calculateVolume = useCallback(
    (length, width, height, quantity) => {
      if (measurementUnit === "metric") {
        return roundToThree(((length * width * height) / 1000000) * quantity);
      } else if (measurementUnit === "imperial") {
        return roundToThree(((length * width * height)) * quantity / CubicFeetMultiplier);
      } else {
        throw new Error('Unsupported unit. Please use "metric" or "imperial".');
      }
    },
    [measurementUnit],
  );


  const calculateLDM = useCallback(
    (length, width, quantity) => {
      if (measurementUnit === "metric") {
        return roundToTwo(((length * width) / 24000) * quantity);
      } else if (measurementUnit === "imperial") {
        return roundToTwo(((length * width) / 3840) * quantity);
      } else {
        throw new Error('Unsupported unit. Please use "metric" or "imperial".');
      }
    },
    [measurementUnit],
  );

  return { calculateVolume, calculateLDM, setMeasurementUnit, units, dimensions, pallet };
};

export default useMeasurement;

function roundToThree(num) {
  num = num.toFixed(5);
  return +(Math.ceil(num + "e+3") + "e-3");
}

function roundToTwo(num) {
  num = num.toFixed(5);
  return +(Math.ceil(num + "e+2") + "e-2");
}