import React, { Fragment, useState } from "react";
import { NotificationManager } from "react-notifications";
import Auth from './Authentication'
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import { ApiUrl } from "../helpers/ApiUrl";

const SignIn = () => {
    const initialFormState = { email: '', password: '' }
    const [signInParams, setSignInParams] = useState(initialFormState)
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setSignInParams({ ...signInParams, [name]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post(ApiUrl() + '/api/v1/get_api_token.json', {...signInParams},
        {
          headers: {
            "Content-Type": "application/json; Charset=UTF-8"
          },
        }).then((resp) => {
            if (resp.status === 200) {
                Auth.login(signInParams.email, resp.data.api_token)
                navigate("/home");
            }
        })
        .catch(function (error) {
            console.log(error.response);
            Auth.logout();
            navigate("/sign_in");
            NotificationManager.error("Wrong username or password.", "Authentication failed!", 3000);
        });
    }

    return (
        <Fragment>
        <div className="max-height landing-bg">
            <div className="col-md-6 col-12">
                <nav className="my-navbar">
                    <div className="center-navbar d-flex align-items-center">
                        <Link to={'/sign_in'} className="navbar-brand logo">
                            <h6 className="cargo-header">Cargo</h6>
                            <h6 className="cargo-header">Price</h6>
                            <h6 className="cargo-header">List</h6>
                        </Link>
                    </div>
                </nav>
                <div className="padding-5">
                <div className="log-in-container">
                <h2 className="pb-3 fw-800">Log in</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group my-3">
                        <input type="email"
                            name="email"
                            value={signInParams.email}
                            onChange={handleInputChange}
                            className="default-input form-control"
                            placeholder="Email"
                            id="user_email" />
                    </div>
                    <div className="form-group my-3">
                        <input type="password"
                            name="password"
                            value={signInParams.password}
                            onChange={handleInputChange}
                            className="default-input form-control"
                            placeholder="Password"
                            id="user_password" />
                    </div>
                    <button type="submit" className="btn log-in-button">Log in</button>
                    <div className="credentials-info">Get your credentials from <a href="mailto: support@cargopricelist.com" className="text-black text-decoration-none">support@cargopricelist.com</a></div>
                </form>
                </div>
                </div>
            </div>
            <div className="col-md-6 col-12 container pb-5 px-5 landing-image"></div>
        </div>
        </Fragment>
    )
}

export default SignIn