class Auth {

    login(username, token) {
        const expiryValue = new Date();
        expiryValue.setHours(expiryValue.getHours() + 6);
        var object = {token: token, expiry: expiryValue.getTime(), username: username}
        localStorage.setItem('api_key', JSON.stringify(object))
    }

    logout() {
        localStorage.removeItem('api_key')
    }

    isLoggedIn() {
        const object = JSON.parse(localStorage.getItem("api_key"))

        if (object && object.token && object.expiry >= new Date().getTime()) {
            return true;
        } else {
            this.logout();
            return false;
        }
    }

    getApiToken() {
        if (this.isLoggedIn()) {
            return JSON.parse(localStorage.getItem("api_key")).token
        } else {
            return false;
        }
    }

    getUsername() {
        if (this.isLoggedIn()) {
            return JSON.parse(localStorage.getItem("api_key")).username
        } else {
            return false;
        }
    }
}

export default new Auth()