import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import App from './App';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
      <Router>
        <Routes>
          <Route path="*" element={<App />}/>
        </Routes>
      </Router>
);
