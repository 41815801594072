import { countriesList } from "../helpers/Countries";

function LocationForm({priceFinderParams, handleInputChange, handleDirectionChange, direction}) {
  return (
    <>
      <div className="col-12">
        <h6 className="calculator-label">LOCATION INFORMATION</h6>
      </div>
      <div className="col-lg-4 col-12 input-div form-floating">
        <select
          name="location_country"
          value={priceFinderParams.location_country}
          onChange={handleInputChange}
          className="default-input fw-600 form-control"
        >
          {countriesList}
        </select>
        <label className="input-label">Location country</label>
      </div>
      <div className="col-lg-4 col-12 input-div form-floating">
        <input
          type="text"
          name="location_postcode"
          value={priceFinderParams.location_postcode}
          onChange={handleInputChange}
          className="default-input fw-600 form-control"
        />
        <label className="input-label">Location postcode</label>
      </div>
      <div className="col-lg-4 col-12 input-div form-floating">
        <select
          name="direction"
          value={direction}
          onChange={handleDirectionChange}
          className="default-input fw-600 form-control"
        >
          <option value="Outbound">Outbound</option>
          <option value="Inbound">Inbound</option>
        </select>
        <label className="input-label">Direction</label>
      </div>
    </>
  )
}

export default LocationForm