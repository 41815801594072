import { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Auth from "./Authentication";
import Results from "./Results";
import MultipleRowsModal from "./MultipleRowsModal";
import LocationForm from "./LocationForm";
import { packageUnitsList, initialFormState } from "../helpers/GoodsHelper";
import { countriesList } from "../helpers/Countries";
import { ApiUrl } from "../helpers/ApiUrl";
import NavBar from "./NavBar";
import { Modal } from "bootstrap";
import useMeasurement from "./hooks/useMeasurement";

const Main = () => {
  const [currentPrices, setCurrentPrices] = useState([]);
  const [userData, setUserData] = useState([]);
  const [direction, setDirection] = useState("Outbound");
  const [adr, setAdr] = useState(false);
  const [frigo, setFrigo] = useState(false);
  const [private_person, setPrivatePerson] = useState(false);
  const [priceFinderParams, setParams] = useState(initialFormState);
  const [cargosonBookingAccess, setCargosonBookingAccess] = useState(false);
  const navigate = useNavigate();
  const { calculateVolume, calculateLDM, units, dimensions, setMeasurementUnit } = useMeasurement();

  useEffect(() => {
    if (userData.measurement_units) setMeasurementUnit(userData.measurement_units)
  }, [userData.measurement_units])

  useEffect(() => {
    axios.post(ApiUrl() + "/api/v1/get_user_data.json", { access_token: Auth.getApiToken() },
      {
        headers: {
          "Content-Type": "application/json; Charset=UTF-8"
        },
      }).then((resp) => {
        if (resp.status === 200) {
          var responseData = resp.data;
          var username = Auth.getUsername();
          setUserData({
            company_name: responseData.company_name,
            company_logo: responseData.company_logo,
            company_postcode: responseData.company_postcode,
            company_country: responseData.company_country,
            username: username,
            measurement_units: responseData.measurement_units,
          });
          setParams(params => ({
            ...params,
            location_postcode: responseData.company_postcode,
            location_country: responseData.company_country,
            destination_country: responseData.company_country,
          }));

          setCargosonBookingAccess(responseData.allow_booking_in_cargoson);
        };
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    if (!priceFinderParams.calculate_multiple_pallets)
      setDimensions(priceFinderParams.package_type);
  }, [priceFinderParams.package_type]);

  useEffect(() => {
    if (priceFinderParams.calculate_multiple_pallets) {
      handleSubmit(null);
    }
  }, [priceFinderParams.calculate_multiple_pallets]);

  useEffect(() => {
    if (!priceFinderParams.calculate_multiple_pallets) calculateCbmAndLdm();
  }, [
    priceFinderParams.length,
    priceFinderParams.width,
    priceFinderParams.quantity,
  ]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "height") {
      let cbm_value = calculateVolume(
        priceFinderParams.length,
        priceFinderParams.width,
        value,
        priceFinderParams.quantity,
      );
      setParams({ ...priceFinderParams, height: value, cbm: cbm_value });
    } else {
      setParams({ ...priceFinderParams, [name]: value });
    }
  };

  const setDimensions = (package_type) => {
    setParams({
      ...priceFinderParams,
      length: dimensions.length[package_type] ?? 0,
      width: dimensions.width[package_type] ?? 0,
      height: dimensions.height[package_type] ?? 0,
      ldm: package_type === "LOAD" ? 13.6 : "",
    });
  };

  const calculateCbmAndLdm = () => {
    if (
      priceFinderParams.package_type === "LOAD" &&
      priceFinderParams.length === "" &&
      priceFinderParams.width === ""
    )
      return;
    let ldm_value = 0;

    let cbm_value = calculateVolume(
      priceFinderParams.length,
      priceFinderParams.width,
      priceFinderParams.height,
      priceFinderParams.quantity,
    );
    if (
      priceFinderParams.package_type !== "CTN" ||
      priceFinderParams.weight / priceFinderParams.quantity > 35
    ) {
      ldm_value = calculateLDM(priceFinderParams.length, priceFinderParams.width, priceFinderParams.quantity);
    }

    setParams({ ...priceFinderParams, cbm: cbm_value, ldm: ldm_value });
  };

  const handleDirectionChange = (event) => {
    const { value } = event.target;
    setDirection(value);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    let outbound = direction === "Outbound";
    setCurrentPrices((currentPrices) => ["Loading"]);

    let collection_postcode = outbound
      ? priceFinderParams.location_postcode
      : priceFinderParams.destination_postcode;
    let collection_country = outbound
      ? priceFinderParams.location_country
      : priceFinderParams.destination_country;
    let delivery_postcode = outbound
      ? priceFinderParams.destination_postcode
      : priceFinderParams.location_postcode;
    let delivery_country = outbound
      ? priceFinderParams.destination_country
      : priceFinderParams.location_country;

    axios
      .post(
        ApiUrl() + `/api/freightPrices/list`,
        {
          collection_date: priceFinderParams.collection_date,
          collection_country: collection_country,
          collection_postcode: formatPostcode(
            collection_postcode,
            collection_country,
          ),
          delivery_country: delivery_country,
          delivery_postcode: formatPostcode(
            delivery_postcode,
            delivery_country,
          ),
          adr: adr,
          frigo: frigo,
          delivery_to_private_person: private_person,
          request_external_partners: true,
          calculate_click: true,
          rows_attributes: rows_attributes(priceFinderParams),
          options: {
            measurement_units: userData.measurement_units
          },
        },
        {
          headers: {
            "Content-Type": "application/json; Charset=UTF-8",
            Authorization: "Token " + Auth.getApiToken(),
          },
        },
      )
      .then((resp) => {
        setCurrentPrices([]);
        if (resp.status == "200") {
          let priceResponse = resp.data.object.prices;
          Object.values(priceResponse).forEach((priceData) =>
            setCurrentPrices((currentPrices) => [
              ...currentPrices,
              {
                service: priceData["carrier"] + " - " + priceData["service"],
                price: priceData["surcharges"].find(
                  (x) => x["identifier"] === "transport_price",
                )?.amount,
                total: priceData["price"],
                priceType: priceData["type"],
                transitTime: priceData["transit_time"],
                estimatedCollection: priceData["estimated_collection_date"],
                estimatedDelivery: priceData["estimated_delivery_date"],
                surchargesBreakdown: priceData["surcharges"]
                  .filter((x) => x["identifier"] !== "transport_price")
                  .map((x) => ({
                    amount: x["amount"],
                    name: humanizeSurcharge(x),
                  })),
                currency: priceData["currency"],
              },
            ]),
          );
        } else {
          setCurrentPrices((currentPrices) => ["No prices"]);
        }
      })
      .catch(function (error) {
        setCurrentPrices((currentPrices) => ["No prices"]);
        if (error.response.status == "401") {
          console.log(error.response);
          Auth.logout();
          navigate("/sign_in");
          NotificationManager.error(
            "API authentication failed, please log in again.",
            "Error!",
            5000,
          );
        } else if (error.response.status == "422") {
          var errorMessage =
            "Your request could not be processed. Please check your data";
          if (error.response.data.errors?.length > 0) {
            errorMessage = error.response.data.errors[0];
          }
          NotificationManager.error(errorMessage, "Error!", 5000);
        }
      });
    setParams({ ...priceFinderParams, calculate_multiple_pallets: false, pallets: [] });
    hideCalculator();
  };

  function hideCalculator() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      document.getElementById("calculator").style.display = "none";
      document.getElementById("results").style.display = "block";
    }
  }

  const openGoodsModal = () => {
    let multiplePalletsModal = new Modal(
      document.getElementById("multiplePalletsModal"),
    );
    multiplePalletsModal.show();
  };

  const setShipmentTotalValues = (totals) => {
    setParams({
      ...priceFinderParams,
      ldm: totals.ldm,
      cbm: totals.cbm,
      weight: totals.weight,
      package_type: totals.package_type,
      quantity: totals.quantity,
      width: "",
      length: "",
      height: "",
      calculate_multiple_pallets: true,
      pallets: totals.pallets,
    });
  };

  return (
    <Fragment>
      <div className="max-height calculator-bg">
        <div className="col-md-6 col-12" id="calculator">
          <NavBar location="nav-calculator" data={userData} />
          <div className="padding-5">
            <div className="calculator-container">
              <h2 className="calculator-header">Price calculator</h2>
              <form onSubmit={handleSubmit} className="row">
                <LocationForm
                  direction={direction}
                  handleDirectionChange={handleDirectionChange}
                  handleInputChange={handleInputChange}
                  priceFinderParams={priceFinderParams}
                />
                <hr className="mt-4 mb-4"></hr>

                <div className="col-12">
                  <h6 className="calculator-label">
                    <span>CARGO INFORMATION</span>
                    <span
                      className="pallets-modal-button float-end d-none d-lg-block"
                      onClick={openGoodsModal}
                    >
                      + Add multiple pallets
                    </span>
                  </h6>
                </div>
                <div className="col-md-6 col-12 input-div form-floating">
                  <select
                    name="destination_country"
                    value={priceFinderParams.destination_country}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  >
                    {countriesList}
                  </select>
                  <label className="input-label">Country</label>
                </div>
                <div className="col-md-6 col-12 input-div form-floating">
                  <input
                    type="text"
                    name="destination_postcode"
                    value={priceFinderParams.destination_postcode}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  />
                  <label className="input-label">Postcode</label>
                </div>
                <div className="col-md-6 col-12 input-div form-floating">
                  <input
                    type="text"
                    name="quantity"
                    value={priceFinderParams.quantity}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  />
                  <label className="input-label"># of packages</label>
                </div>
                <div className="col-md-6 col-12 input-div form-floating">
                  <select
                    name="package_type"
                    value={priceFinderParams.package_type}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  >
                    {packageUnitsList}
                  </select>
                  <label className="input-label">Type PKG</label>
                </div>
                <div className="col-md-6 col-12 input-div form-floating">
                  <input
                    type="text"
                    name="ldm"
                    value={priceFinderParams.ldm}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  />
                  <label className="input-label">LDM</label>
                </div>
                <div className="col-md-6 col-12 input-div form-floating">
                  <input
                    type="text"
                    name="weight"
                    value={priceFinderParams.weight}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  />
                  <label className="input-label">Weight ({units.weight})</label>
                </div>
                <div className="col-md-4 col-12 input-div form-check">
                  <input
                    id="adr"
                    type="checkbox"
                    name="adr"
                    checked={adr}
                    onChange={() => setAdr(!adr)}
                    className="default-input ms-0 me-1 form-check-input"
                  />
                  <label className="input-label form-check-label" htmlFor="adr">
                    Dangerous goods
                  </label>
                </div>
                <div className="col-md-4 col-12 input-div form-check">
                  <input
                    id="frigo"
                    type="checkbox"
                    name="frigo"
                    checked={frigo}
                    onChange={() => setFrigo(!frigo)}
                    className="default-input ms-0 me-1 form-check-input"
                  />
                  <label className="input-label form-check-label" htmlFor="frigo">
                    Temperature sensitive
                  </label>
                </div>
                <div className="col-md-4 col-12 input-div form-check">
                  <div className="float-start float-md-end">
                    <input
                      id="private_person"
                      type="checkbox"
                      name="private_person"
                      checked={private_person}
                      onChange={() => setPrivatePerson(!private_person)}
                      className="default-input ms-0 me-1 form-check-input"
                    />
                    <label
                      className="input-label form-check-label"
                      htmlFor="private_person"
                    >
                      Private person
                    </label>
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>

                <div className="col-12">
                  <h6 className="calculator-label">
                    PACK DIMENSIONS (OPTIONAL)
                  </h6>
                </div>
                <div className="col-md-3 col-12 input-div form-floating">
                  <input
                    type="text"
                    name="length"
                    value={priceFinderParams.length}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  />
                  <label className="input-label">Length ({units.distance})</label>
                </div>
                <div className="col-md-3 col-12 input-div form-floating">
                  <input
                    type="text"
                    name="width"
                    value={priceFinderParams.width}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  />
                  <label className="input-label">Width ({units.distance})</label>
                </div>
                <div className="col-md-3 col-12 input-div form-floating">
                  <input
                    type="text"
                    name="height"
                    value={priceFinderParams.height}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  />
                  <label className="input-label">Height ({units.distance})</label>
                </div>
                <div className="col-md-3 col-12 input-div form-floating">
                  <input
                    type="text"
                    name="cbm"
                    value={priceFinderParams.cbm}
                    onChange={handleInputChange}
                    className="default-input fw-600 form-control"
                  />
                  <label className="input-label">{units.volume}</label>
                </div>

                <button
                  type="submit"
                  className="btn calculator-button"
                  onClick={hideCalculator}
                >
                  Calculate
                </button>
                {cargosonBookingAccess ? (
                  <a
                    href={ApiUrl() + "/queries/new?origin=cargopricelist"}
                    target="_blank"
                    className="btn order-button"
                  >
                    Book Shipment
                  </a>
                ) : null}
                <small className="text-muted fw-light ps-0 mt-2">
                  Powered by Cargoson
                </small>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12" id="results">
          <NavBar location="nav-results" data={userData} />
          <div className="calculator-image pb-5 px-3">
            <Results prices={currentPrices}></Results>
          </div>
        </div>
      </div>
      {userData.measurement_units && (
        <MultipleRowsModal
          setShipmentTotalValues={setShipmentTotalValues}
          unit={userData.measurement_units}
        ></MultipleRowsModal>
      )}
    </Fragment>
  );
};



function humanizeSurcharge(surhcarge) {
  if (surhcarge["name"]) {
    return surhcarge["name"].toUpperCase();
  } else {
    return surhcarge["identifier"].replace("_", " ").toUpperCase();
  }
}

function formatPostcode(postcode, country) {
  if (country === "LV") {
    return postcode.replace(/\D/g, "");
  } else {
    return postcode;
  }
}

function rows_attributes(priceFinderParams) {
  if (priceFinderParams.calculate_multiple_pallets) {
    const rows = Object.fromEntries(priceFinderParams.pallets.map((pallet, index) =>
      [index, {
        cbm: pallet.cbm === 0 ? null : pallet.cbm.toString()?.replace(",", "."),
        description: pallet.description,
        height: pallet.height,
        ldm: pallet.ldm.toString()?.replace(",", "."),
        length: pallet.length,
        package_type: pallet.type,
        quantity: pallet.quantity,
        weight: pallet.weight
          ? pallet.weight.toString()?.replace(",", ".")
          : pallet.package_type === "LOAD"
            ? 24000
            : 1,
        width: pallet.width
      }]))
    return rows

  } else {
    return {
      0: {
        cbm:
          priceFinderParams.cbm === 0
            ? null
            : priceFinderParams.cbm.toString()?.replace(",", "."),
        description: priceFinderParams.description,
        height: priceFinderParams.height,
        ldm: priceFinderParams.ldm.toString()?.replace(",", "."),
        length: priceFinderParams.length,
        package_type: priceFinderParams.package_type,
        quantity: priceFinderParams.quantity,
        weight: priceFinderParams.weight
          ? priceFinderParams.weight.toString()?.replace(",", ".")
          : priceFinderParams.package_type === "LOAD"
            ? 24000
            : 1,
        width: priceFinderParams.width,
      },
    }
  }
}

export default Main;
