import { useState, useEffect } from "react";
import FormPalletRow from "./FormPalletRow";
import useMeasurement from "./hooks/useMeasurement";

function MultipleRowsModal({setShipmentTotalValues, unit}) {
  const { calculateVolume, calculateLDM, units, dimensions, pallet } = useMeasurement(unit);
  const palletData = {...pallet}
  const [palletRows, setPalletRows] = useState([palletData]);

  const [shipmentTotal, setShipmentTotal] = useState({
    quantity: 1,
    weight: 0,
    cbm: 0,
    ldm: 0.4,
    package_type: "EUR"
  });

  useEffect(() => {
    calculateTotals(palletRows)
  }, [palletRows])
  

  const removeRow = (id) => {    
    setPalletRows(palletRows => palletRows.filter((pallet) => palletRows.indexOf(pallet) !== id));
  };

  const addRow = () => {
    setPalletRows((prevState) => {
      return [...prevState, palletData];
    });    
  };

  const handleInputChange = (event, id) => {
    const { name, value } = event.target;
    let rowsArray = [...palletRows];
    let obj = rowsArray[id];
    obj[name] = value;
    if (name === "type") {
      obj.length = dimensions.length[value] ?? 0;
      obj.width = dimensions.width[value] ?? 0;
      obj.height = "";
      obj.weight = "";
    }
    obj.ldm = calculateLDM(obj.length, obj.width, obj.quantity);
    obj.cbm = calculateVolume(obj.length, obj.width, obj.height, obj.quantity);

    rowsArray[id] = obj;
    setPalletRows([...rowsArray]);
  };

  const calculateTotals = (pallets) => {
    let totalQuantity = pallets.reduce((a, b) => a + parseInt(b.quantity || 0), 0);
    let totalLDM = pallets.reduce((a, b) => a + roundToTwo(b.ldm || 0), 0);
    let totalCBM = pallets.reduce((a, b) => a + roundToThree(b.cbm || 0), 0);
    let totalWeight = pallets.reduce((a, b) => a + roundToTwo(b.weight || 0), 0);
    let unique_package_types = [...new Set(pallets.map(item => item.type))];
    let package_type = (unique_package_types.length === 1) ? unique_package_types[0] : "PLL";
    setShipmentTotal({
      ...shipmentTotal,
      quantity: totalQuantity || 0,
      ldm: roundToTwo(totalLDM) || 0,
      cbm: roundToThree(totalCBM) || 0,
      weight: totalWeight || 0,
      package_type: package_type,
      pallets: pallets
    });
  };

  return (
    <div className="modal" tabIndex="-1" id="multiplePalletsModal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content" style={{ borderRadius: "0px" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Multiple pallets calculator
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body row ms-1">
            <div className="col-2 px-1 mb-2" style={{ width: "10%" }}>
              <label className="input-label">Quantity</label>
            </div>
            <div className="col-2 px-1 mb-2" style={{ width: "11%" }}>
              <label className="input-label">Type</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "13%" }}>
              <label className="input-label">Length ({units.distance})</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "13%" }}>
              <label className="input-label">Width ({units.distance})</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "13%" }}>
              <label className="input-label">Height ({units.distance})</label>
            </div>
            <div className="col-1 px-1 mb-2 text-center" style={{ width: "10%" }}>
              <label className="input-label">LDM</label>
            </div>
            <div className="col-1 px-1 mb-2 text-center" style={{ width: "10%" }}>
              <label className="input-label">{units.cbm}</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "12%" }}>
              <label className="input-label">Weight</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "8%" }}></div>
            {palletRows.map((palletRow, key) => (
              <FormPalletRow
                palletRow={palletRow}
                id={key}
                key={key}
                removeRow={removeRow}
                handleInputChange={handleInputChange}
              ></FormPalletRow>
            ))}
            <div className="col-12 mt-2">
              <span className="calculator-label pallets-modal-button" onClick={() => addRow()}>+ Add pallet</span>
            </div>
            <hr className="mt-3"></hr>
            <div className="col-12 mt-3">
              <span className="me-3">
                <b>Total pieces: {shipmentTotal.quantity}</b>
              </span>
              <span className="me-3">
                <b>LDM: {shipmentTotal.ldm}</b>
              </span>
              <span className="me-3">
                <b>{units.cbm}: {shipmentTotal.cbm}</b>
              </span>
              <span>
                <b>Weight: {shipmentTotal.weight}</b>
              </span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm calculator-button"
              data-bs-dismiss="modal"
              onClick={() => setShipmentTotalValues(shipmentTotal)}
            >
              Save & Calculate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function roundToTwo(num) {
  return + (Math.round(num + "e+2") + "e-2");
}

function roundToThree(num) {
  return + (Math.round(num + "e+3") + "e-3");
}

export default MultipleRowsModal;
