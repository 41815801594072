export const DimensionSettings = {
    "length": {
        "EUR": 120,
        "FIN": 120,
        "HPL": 80,
        "QPL": 60,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "width": {
        "EUR": 80,
        "FIN": 100,
        "HPL": 60,
        "QPL": 40,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "height": {
        "EUR": "",
        "FIN": "",
        "HPL": "",
        "QPL": "",
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    }
}

export const ImperialDimensionSettings = {
    "length": {
        "EUR": 48,
        "FIN": 48,
        "HPL": 32,
        "QPL": 24,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "width": {
        "EUR": 32,
        "FIN": 40,
        "HPL": 24,
        "QPL": 16,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "height": {
        "EUR": "",
        "FIN": "",
        "HPL": "",
        "QPL": "",
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    }
}

export const PackageOptions = {
    "EUR": "EUR pallet (EUR)",
    "FIN": "FIN pallet (FIN)",
    "HPL": "Half pallet (HPL)",
    "QPL": "Quarter pallet (QPL)",
    "CTN": "Package (CTN)",
    "PLL": "Pallet (PLL)",
    "LOAD": "Full load"
}

export const packageUnitsList = Object.entries(PackageOptions).map(
    (packageOption) => {
      return (
        <option value={packageOption[0]} key={packageOption[0]}>
          {packageOption[1]}
        </option>
      );
    },
  );

export const PalletData = {
    type: "EUR",
    quantity: 1,
    length: 120,
    width: 80,
    height: "",
    weight: "",
    cbm: 0,
    ldm: 0.4,
  };

export const ImperialPalletData = {
    type: "EUR",
    quantity: 1,
    length: 48,
    width: 32,
    height: "",
    weight: "",
    cbm: 0,
    ldm: 0.4,
  };

  export const initialFormState = {
    collection_date: new Date()
      .toJSON()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("."),
    location_country: "EE",
    location_postcode: "12345",
    destination_country: "EE",
    destination_postcode: "12345",
    quantity: "1",
    package_type: "CTN",
    weight: "",
    cbm: "0",
    length: "0",
    width: "0",
    height: "0",
    ldm: "",
    description: "Goods",
    calculate_multiple_pallets: false,
  };